import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  BillType,
  EmitTrasnSocketNotify,
  IEdubillDetail,
  IStudeninfor,
  ProfileEmail,
  ProfileFB,
  SCHOOL_CODE,
} from './../types/general'

export interface Type_Initstate {
  loading: boolean
  isHome: boolean
  fbAccount: ProfileFB | undefined
  ggAccount: ProfileEmail | undefined
  schoolCode: SCHOOL_CODE['CODE']
  hidden: boolean
  studenInfor: IStudeninfor | undefined
  billList: BillType[]
  device: string
  edubillDetail: IEdubillDetail | undefined
  listEdubillDetail: IEdubillDetail[]
  trasnSocketNotify: EmitTrasnSocketNotify | undefined
}

const initialState: Type_Initstate = {
  isHome: false,
  fbAccount: undefined,
  ggAccount: undefined,
  schoolCode: undefined,
  studenInfor: undefined,
  billList: undefined,
  hidden: false,
  loading: false,
  device: 'web',
  edubillDetail: undefined,
  trasnSocketNotify: undefined,
  listEdubillDetail: [],
}

const appSlice = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setHidden: (state, action: PayloadAction<boolean>) => {
      state.hidden = action.payload
    },
    setGGAccount: (state, action: PayloadAction<ProfileEmail>) => {
      state.ggAccount = action.payload
    },
    setFBAccount: (state, action: PayloadAction<ProfileFB>) => {
      state.fbAccount = action.payload
    },
    setSchoolCode: (state, action: PayloadAction<SCHOOL_CODE['CODE']>) => {
      state.schoolCode = action.payload
    },
    setStudenInfor: (state, action: PayloadAction<IStudeninfor | undefined>) => {
      state.studenInfor = action.payload
    },
    setBillList: (state, action: PayloadAction<BillType[]>) => {
      state.billList = action.payload
    },
    setIsHome: (state, action: PayloadAction<boolean>) => {
      state.isHome = action.payload
    },
    setDevice: (state, action: PayloadAction<string>) => {
      state.device = action.payload
    },
    setEduBillDetail: (state, action: PayloadAction<IEdubillDetail | undefined>) => {
      state.edubillDetail = action.payload
    },
    setListEduBillDetail: (state, action: PayloadAction<IEdubillDetail[] | any>) => {
      state.listEdubillDetail = action.payload
    },
    setTrasnSocketNotify: (state, action: PayloadAction<EmitTrasnSocketNotify>) => {
      state.trasnSocketNotify = action.payload
    },
  },
})

export const {
  setLoading,
  setGGAccount,
  setFBAccount,
  setSchoolCode,
  setHidden,
  setStudenInfor,
  setBillList,
  setIsHome,
  setDevice,
  setEduBillDetail,
  setListEduBillDetail,
  setTrasnSocketNotify,
} = appSlice.actions

export default appSlice.reducer
