import Button from '@components/Button'
import Input from '@components/Input'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Type_Initstate } from 'store/appSlice'
import { BillsHistory } from '../../../types/general'

interface IProps {
  handleGetBillList: (maSv: string, cccd: string) => Promise<void>
  isHistory?: boolean
  bills?: BillsHistory[]
  valueSearch1?: string
}

type IFrom = {
  maSV: string
  cccd?: string
}

const Search: FC<IProps> = ({ handleGetBillList, isHistory, valueSearch1 }: IProps) => {
  const { edubillDetail } = useSelector<RootState, Type_Initstate>((state) => state.app)
  const [valueSearch, setValueSearch] = useState<IFrom | undefined>(undefined)

  const handleSetValue = (value: string | null, key: string) => {
    setValueSearch({ ...valueSearch, [`${key}`]: value.trim() })
  }

  return (
    <div className="md:flex justify-center w-full items-center">
      <div
        className={`p-4 bg-white flex md:justify-between flex-col rounded-[16px] max-w-[1040px] w-full absolute md:top-[-90px] ${
          !isHistory ? 'top-[-130px]' : `${valueSearch1 ? 'top-[-140px]' : 'top-[-240px]'}`
        }`}
        style={{
          boxShadow: '0px 4px 54px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="w-full ">
          {edubillDetail?.isSearchMaSV && (
            <div className="flex md:items-center mb-3 flex-col md:flex-row">
              <p className="text-[#595959] text-[16px] pr-2 md:w-[180px] font-[400]">Mã học viên</p>
              <Input onChange={(e) => handleSetValue(e, 'maSV')} placeholder="Nhập mã học viên" />
            </div>
          )}
          {edubillDetail?.isSearchCCCD && (
            <div className="flex md:items-center mb-3 flex-col md:flex-row">
              <p className="text-[#595959] text-[16px] pr-2 md:w-[180px] font-[400]">Căn cước công dân</p>
              <Input onChange={(e) => handleSetValue(e, 'cccd')} placeholder="Nhập CCCD" />
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <Button
            text="Tra cứu học phí"
            onClick={() =>
              handleGetBillList(
                edubillDetail?.isSearchMaSV ? valueSearch.maSV : '0',
                edubillDetail?.isSearchCCCD ? valueSearch.cccd : '0',
              )
            }
            disabled={edubillDetail?.isSearchCCCD ? !valueSearch?.cccd : false || !valueSearch?.maSV}
            width="w-full md:w-[170px]"
          />
        </div>
      </div>
    </div>
  )
}

export default Search
