import { Tooltip } from 'antd'
import React, { FC, useState } from 'react'
import Loading from './Loading'

interface IProps {
  text: string
  disabled?: boolean
  isToolTip?: boolean
  onClick?: () => void
  type?: 'main' | 'default'
  width?: string
  loading?: boolean
}

const Button: FC<IProps> = ({ text, onClick, type = 'main', disabled, isToolTip, width, loading }: IProps) => {
  const [isShown, setIsShown] = useState(false)

  return (
    <>
      <button
        disabled={disabled}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={() => !disabled && onClick && onClick()}
        className={`rounded-[10px] shadow-[#00000040] ${width} flex justify-center items-center ${
          type === 'main'
            ? `${disabled ? 'bg-[#39627ecc]' : 'bg-[#045993]'}`
            : `${disabled ? 'bg-[#ffffff1a]' : 'bg-white'}`
        }  p-2 px-6 text-[16px] font-[500] shadow-lg  ${
          type === 'main' ? 'text-white' : 'text-[#595959]'
        } h-[43px] md:h-[51px] ${
          type === 'main' ? 'hover:bg-[#045993cc]' : 'hover:bg-[#ffffff1a]'
        } focus:outline-none focus:shadow-outline transition duration-500 ease-in-out w-[170px]
          ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
        `}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            {isToolTip && (
              <Tooltip open={isShown} title="Chọn phiếu thu cần thanh toán">
                {text}
              </Tooltip>
            )}
            {!isToolTip && text}
          </>
        )}
      </button>
    </>
  )
}

export default Button
