import { TypeMethodPayment } from '../types/general'

export const CODE_DEFAULT = -200
export const CODE_SUCCESS = 200
export const ERROR_NETWORK_CODE = -100
export const RESULT_CODE_PUSH_OUT = 401
export const TIME_OUT = 90000
export const STATUS_TIME_OUT = 'ECONNABORTED'
export const CODE_TIME_OUT = 408

export const KEY_COOKIE = 'isHuman'
export const Version = 'v240108.1'

export const KEY_SCHOOL = {
  HV: 'sg',
  TN: 'tn',
}

export const PaymentMethodKey = {
  VETTELMONEY: 'VETTELMONEY',
  VNPAYQR: 'VNPAYQR',
  VNMART: 'VNMART',
  WALLET: 'WALLET',
  ONEPAY: 'ONEPAY',
  VNPAY: 'VNPAY',
  VISA: 'VISA',
  BIDV: 'BIDV',
  ATM: 'ATM',
  SHB: 'SHB',
  ACB: 'ACB',
  QR: 'QR',
}

// hvsg
// hvsg_uat
export enum SchoolCodeEnum {
  DHHV = 'hvsg',
  TNUT = 'tnut',
  HCMUI = 'hcmiu',
  // dhhv = 'dhhv',
  // 'dhhv' | 'tnut' | undefined
}

export enum OnepayMethodEnum {
  INTERNATIONAL = 'VISA', // The thanh toan quoc te
  DOMESTIC = 'ATM', // The ATM
  QR = 'QR', // Tanh toan qua ung ung, vi dien tu
}

export const LIST_VISA = [
  {
    id: 1,
    name: 'VISA',
    code: 'VISA',
    bin: '970416',
    shortName: 'VISA',
    logo: 'VISA.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'VISA',
    support: 3,
    isTransfer: 1,
    swift_code: 'ASCBVNVX',
  },
  {
    id: 2,
    name: 'MASTERCARD',
    code: 'MASTERCARD',
    bin: '970416',
    shortName: 'MASTERCARD',
    logo: 'MASTERCARD.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'MASTERCARD',
    support: 3,
    isTransfer: 1,
    swift_code: 'ASCBVNVX',
  },
  {
    id: 3,
    name: 'JCB',
    code: 'JCB',
    bin: '970416',
    shortName: 'JCB',
    logo: 'JCB.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'JCB',
    support: 3,
    isTransfer: 1,
    swift_code: 'ASCBVNVX',
  },
  {
    id: 4,
    name: 'AMEX',
    code: 'AMEX',
    bin: '970416',
    shortName: 'AMEX',
    logo: 'AMERICANEXPRESS.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'AMEX',
    support: 3,
    isTransfer: 1,
    swift_code: 'ASCBVNVX',
  },
]

export const listSchool = [
  {
    schoolName: 'ĐẠI HỌC THÁI NGUYÊN <br>TRƯỜNG ĐẠI HỌC KỸ THUẬT CÔNG NGHIỆP</br>',
    school: 'tnut',
    orgCode: 'dhtn',
    schoolCode: 'cntn',
    // hard code uat
    orgCodeUat: 'cntn',
    schoolCodeUat: 'cntn_uat',
    // hard code uat
    comingSoon: false,
    avatarUrl: 'https://i.imgur.com/D1ib43L.png',
  },
  {
    schoolName: 'Trường đại học Hùng Vương TPHCM',
    school: 'hvsg',
    orgCode: 'hvsg',
    schoolCode: 'hvsg',
    // hard code uat
    orgCodeUat: 'hvsg',
    schoolCodeUat: 'hvsg_uat',
    // hard code uat
    comingSoon: false,
    avatarUrl: 'https://edu.hoteljob.vn/uploads/images/20-07-13-10/11838946_950600168319174_5349920010974847011_o.jpg',
  },
  {
    name: 'Đại Học Quốc Gia <br>TP.HCM</br> Trường Đại Học Quốc Tế',
    orgCode: 'cntn',
    orgName: 'Đại Học Quốc Gia <br>TP.HCM</br> Trường Đại Học Quốc Tế',
    schoolCode: 'cntn_uat',
    schoolName: 'Đại Học Quốc Gia <br>TP.HCM</br> Trường Đại Học Quốc Tế',
    idSchool: '-4854235522421674785',
    avatarUrl: 'https://i.imgur.com/D1ib43L.png',
    golive: false,
    email: 'cntn_uat@gmail.com',
    fax: '1235',
    phone: '0977651728',
    status: 1,
    comingSoon: true,
  },
]

export const LIST_BANK = [
  {
    id: 2,
    name: 'Ngân hàng TMCP Á Châu',
    code: 'ACB',
    bin: '970416',
    shortName: 'ACB',
    logo: 'ACB.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'ACB',
    support: 3,
    isTransfer: 1,
    swift_code: 'ASCBVNVX',
  },
  {
    id: 4,
    name: 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
    code: 'BIDV',
    bin: '970418',
    shortName: 'BIDV',
    logo: 'BIDV.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'BIDV',
    support: 3,
    isTransfer: 1,
    swift_code: 'BIDVVNVX',
  },
  {
    id: 9,
    name: 'Ngân hàng TMCP Đông Á',
    code: 'DONGABANK',
    bin: '970406',
    shortName: 'DongABank',
    logo: 'DONGABANK.png',
    transferSupported: 0,
    lookupSupported: 1,
    short_name: 'DongABank',
    support: 0,
    isTransfer: 0,
    swift_code: 'EACBVNVX',
  },
  // {
  //   id: 18,
  //   name: 'Ngân hàng TNHH Indovina',
  //   code: 'IVB',
  //   bin: '970434',
  //   shortName: 'IndovinaBank',
  //   logo: 'https://api.vietqr.io/img/IVB.png',
  //   transferSupported: 0,
  //   lookupSupported: 1,
  //   short_name: 'IndovinaBank',
  //   support: 0,
  //   isTransfer: 0,
  //   swift_code: null,
  // },
  {
    id: 21,
    name: 'Ngân hàng TMCP Quân đội',
    code: 'MBBANK',
    bin: '970422',
    shortName: 'MBBank',
    logo: 'MB.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'MBBank',
    support: 3,
    isTransfer: 1,
    swift_code: 'MSCBVNVX',
  },
  {
    id: 22,
    name: 'Ngân hàng TMCP Hàng Hải',
    code: 'MSBANK',
    bin: '970426',
    shortName: 'MSB',
    logo: 'MSB.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'MSB',
    support: 3,
    isTransfer: 1,
    swift_code: 'MCOBVNVX',
  },
  {
    id: 24,
    name: 'Ngân hàng TMCP Quốc Dân',
    code: 'NCB',
    bin: '970419',
    shortName: 'NCB',
    logo: 'NCB.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'NCB',
    support: 3,
    isTransfer: 1,
    swift_code: 'NVBAVNVX',
  },
  {
    id: 31,
    name: 'Ngân hàng TMCP Sài Gòn',
    code: 'SCB',
    bin: '970429',
    shortName: 'SCB',
    logo: 'SACOMBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'SCB',
    support: 3,
    isTransfer: 1,
    swift_code: 'SACLVNVX',
  },
  {
    id: 35,
    name: 'Ngân hàng TMCP Sài Gòn - Hà Nội',
    code: 'SHB',
    bin: '970443',
    shortName: 'SHB',
    logo: 'SHB.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'SHB',
    support: 3,
    isTransfer: 1,
    swift_code: 'SHBAVNVX',
  },
  // {
  //   id: 45,
  //   name: 'Ngân hàng TMCP Quốc tế Việt Nam',
  //   code: 'VIB',
  //   bin: '970441',
  //   shortName: 'VIB',
  //   logo: 'VIB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'VIB',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'VNIBVNVX',
  // },
  // {
  //   id: 46,
  //   name: 'Ngân hàng TMCP Việt Nam Thương Tín',
  //   code: 'VIETBANK',
  //   bin: '970433',
  //   shortName: 'VietBank',
  //   logo: 'Vietbank.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'VietBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'VNTTVNVX',
  // },
  // {
  //   id: 48,
  //   name: 'Ngân hàng Liên doanh Việt - Nga',
  //   code: 'VRB',
  //   bin: '970421',
  //   shortName: 'VRB',
  //   logo: 'https://api.vietqr.io/img/VRB.png',
  //   transferSupported: 0,
  //   lookupSupported: 1,
  //   short_name: 'VRB',
  //   support: 0,
  //   isTransfer: 0,
  //   swift_code: null,
  // },
  {
    id: 26,
    name: 'Ngân hàng TMCP Phương Đông',
    code: 'OCB',
    bin: '970448',
    shortName: 'OCB',
    logo: 'OCB.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'OCB',
    support: 3,
    isTransfer: 1,
    swift_code: 'ORCOVNVX',
  },
  // {
  //   id: 40,
  //   name: 'Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh',
  //   code: 'UOB',
  //   bin: '970458',
  //   shortName: 'UnitedOverseas',
  //   logo: 'https://api.vietqr.io/img/UOB.png',
  //   transferSupported: 0,
  //   lookupSupported: 1,
  //   short_name: 'UnitedOverseas',
  //   support: 0,
  //   isTransfer: 0,
  //   swift_code: null,
  // },
  {
    id: 401,
    name: 'AGRIBANK',
    code: 'AGRIBANK',
    bin: '970458',
    shortName: 'AGRIBANK',
    logo: 'AGRIBANK.png',
    transferSupported: 0,
    lookupSupported: 1,
    short_name: 'AGRIBANK',
    support: 0,
    isTransfer: 0,
    swift_code: null,
  },
  {
    id: 10,
    name: 'Ngân hàng TMCP Xuất Nhập khẩu Việt Nam',
    code: 'EXIMBANK',
    bin: '970431',
    shortName: 'Eximbank',
    logo: 'EXIMBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'Eximbank',
    support: 3,
    isTransfer: 1,
    swift_code: 'EBVIVNVX',
  },
  {
    id: 12,
    name: 'Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh',
    code: 'HDBANK',
    bin: '970437',
    shortName: 'HDBank',
    logo: 'HD BANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'HDBank',
    support: 3,
    isTransfer: 1,
    swift_code: 'HDBCVNVX',
  },
  {
    id: 120,
    name: 'Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh',
    code: 'OJB',
    bin: '970437',
    shortName: 'OJB',
    logo: 'OCEANBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'OJB',
    support: 3,
    isTransfer: 1,
    swift_code: 'HDBCVNVX',
  },
  // {
  //   id: 30,
  //   name: 'Ngân hàng TMCP Đại Chúng Việt Nam',
  //   code: 'PVCOMBANK',
  //   bin: '970412',
  //   shortName: 'PVcomBank',
  //   logo: 'https://api.vietqr.io/img/PVCB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'PVcomBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'WBVNVNVX',
  // },
  {
    id: 38,
    name: 'Ngân hàng TMCP Kỹ thương Việt Nam',
    code: 'TECHCOMBANK',
    bin: '970407',
    shortName: 'Techcombank',
    logo: 'TECHCOMBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'Techcombank',
    support: 3,
    isTransfer: 1,
    swift_code: 'VTCBVNVX',
  },
  {
    id: 39,
    name: 'Ngân hàng TMCP Tiên Phong',
    code: 'TPBANK',
    bin: '970423',
    shortName: 'TPBank',
    logo: 'TPBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'TPBank',
    support: 3,
    isTransfer: 1,
    swift_code: 'TPBVVNVX',
  },
  // {
  //   id: 41,
  //   name: 'Ngân hàng TMCP Việt Á',
  //   code: 'VIETABANK',
  //   bin: '970427',
  //   shortName: 'VietABank',
  //   logo: 'https://api.vietqr.io/img/VAB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'VietABank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'VNACVNVX',
  // },
  // {
  //   id: 44,
  //   name: 'Ngân hàng TMCP Bản Việt',
  //   code: 'VIETCAPITALBANK',
  //   bin: '970454',
  //   shortName: 'VietCapitalBank',
  //   logo: 'https://api.vietqr.io/img/VCCB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'VietCapitalBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'VCBCVNVX',
  // },
  {
    id: 43,
    name: 'Ngân hàng TMCP Ngoại Thương Việt Nam',
    code: 'VIETCOMBANK',
    bin: '970436',
    shortName: 'Vietcombank',
    logo: 'VIETCOMBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'Vietcombank',
    support: 3,
    isTransfer: 1,
    swift_code: 'BFTVVNVX',
  },
  {
    id: 17,
    name: 'Ngân hàng TMCP Công thương Việt Nam',
    code: 'VIETINBANK',
    bin: '970415',
    shortName: 'VietinBank',
    logo: 'VIETINBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'VietinBank',
    support: 3,
    isTransfer: 1,
    swift_code: 'ICBVVNVX',
  },
  {
    id: 47,
    name: 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
    code: 'VPBANK',
    bin: '970432',
    shortName: 'VPBank',
    logo: 'VPBANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'VPBank',
    support: 3,
    isTransfer: 1,
    swift_code: 'VPBKVNVX',
  },
  {
    id: 3,
    name: 'Ngân hàng TMCP Bắc Á',
    code: 'BACABANK',
    bin: '970409',
    shortName: 'BacABank',
    logo: 'BACABANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'BacABank',
    support: 3,
    isTransfer: 1,
    swift_code: 'NASCVNVX',
  },
  // {
  //   id: 33,
  //   name: 'Ngân hàng TMCP Đông Nam Á',
  //   code: 'SEABANK',
  //   bin: '970440',
  //   shortName: 'SeABank',
  //   logo: 'seAbank.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'SeABank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'SEAVVNVX',
  // },
  // {
  //   id: 34,
  //   name: 'Ngân hàng TMCP Sài Gòn Công Thương',
  //   code: 'SAIGONBANK',
  //   bin: '970400',
  //   shortName: 'SaigonBank',
  //   logo: 'https://api.vietqr.io/img/SGICB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'SaigonBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'SBITVNVX',
  // },
  // {
  //   id: 19,
  //   name: 'Ngân hàng TMCP Kiên Long',
  //   code: 'KIENLONGBANK',
  //   bin: '970452',
  //   shortName: 'KienLongBank',
  //   logo: 'https://api.vietqr.io/img/KLB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'KienLongBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'KLBKVNVX',
  // },
  {
    id: 23,
    name: 'Ngân hàng TMCP Nam Á',
    code: 'NAMABANK',
    bin: '970428',
    shortName: 'NamABank',
    logo: 'NAMABANK.png',
    transferSupported: 1,
    lookupSupported: 1,
    short_name: 'NamABank',
    support: 3,
    isTransfer: 1,
    swift_code: 'NAMAVNVX',
  },
  // {
  //   id: 5,
  //   name: 'Ngân hàng TMCP Bảo Việt',
  //   code: 'BAOVIETBANK',
  //   bin: '970438',
  //   shortName: 'BaoVietBank',
  //   logo: 'https://api.vietqr.io/img/BVB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'BaoVietBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'BVBVVNVX',
  // },
  // {
  //   id: 28,
  //   name: 'Ngân hàng TNHH MTV Public Việt Nam',
  //   code: 'PUBLICBANK',
  //   bin: '970439',
  //   shortName: 'PublicBank',
  //   logo: 'https://api.vietqr.io/img/PBVN.png',
  //   transferSupported: 0,
  //   lookupSupported: 1,
  //   short_name: 'PublicBank',
  //   support: 0,
  //   isTransfer: 0,
  //   swift_code: 'VIDPVNVX',
  // },
  // {
  //   id: 29,
  //   name: 'Ngân hàng TMCP Xăng dầu Petrolimex',
  //   code: 'PGBANK',
  //   bin: '970430',
  //   shortName: 'PGBank',
  //   logo: 'https://api.vietqr.io/img/PGB.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'PGBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'PGBLVNVX',
  // },
  // {
  //   id: 1,
  //   name: 'Ngân hàng TMCP An Bình',
  //   code: 'ABBANK',
  //   bin: '970425',
  //   shortName: 'ABBANK',
  //   logo: 'ABBank.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'ABBANK',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'ABBKVNVX',
  // },
  // {
  //   id: 11,
  //   name: 'Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu',
  //   code: 'GPBANK',
  //   bin: '970408',
  //   shortName: 'GPBank',
  //   logo: 'https://api.vietqr.io/img/GPB.png',
  //   transferSupported: 0,
  //   lookupSupported: 1,
  //   short_name: 'GPBank',
  //   support: 0,
  //   isTransfer: 0,
  //   swift_code: 'GBNKVNVX',
  // },
  // {
  //   id: 37,
  //   name: 'Ngân hàng TNHH MTV Shinhan Việt Nam',
  //   code: 'SHINHANBANK',
  //   bin: '970424',
  //   shortName: 'ShinhanBank',
  //   logo: 'https://api.vietqr.io/img/SHBVN.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'ShinhanBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'SHBKVNVX',
  // },
  // {
  //   id: 370,
  //   name: 'Ngân hàng TNHH MTV Shinhan Việt Nam',
  //   code: 'VIETCREDIT',
  //   bin: '970424',
  //   shortName: 'ShinhanBank',
  //   logo: 'https://www.vietcredit.vn/images/logo_v5.png',
  //   transferSupported: 1,
  //   lookupSupported: 1,
  //   short_name: 'ShinhanBank',
  //   support: 3,
  //   isTransfer: 1,
  //   swift_code: 'SHBKVNVX',
  // },
]

export const ERROR_CODE = {
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '402': 'Payment Required ',
  '403': 'Forbidden',
  '404': 'Not Found',
  '405': 'Method Not Allowed',
  '406': 'Not Acceptable',
  '407': 'Proxy Authentication Required',
  '408': 'Request Timeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'Length Required',
  '412': 'Precondition Failed',
  '413': 'Payload Too Large',
  '414': 'URI Too Long',
  '415': 'Unsupported Media Type',
  '416': 'Range Not Satisfiable',
  '417': 'Expectation Failed',
  '500': 'Internal Server Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Timeout',
  '505': 'HTTP Version Not Supported',
  have_error: 'An error occurred. Please try again later',
  error_on_request: 'An error occurred while sending the request',
  error_on_handle: 'An error occurred while processing data',
  server_error: 'Server Error',
}

export const SCHOOLL_INFOR = {
  hcmiu: {
    name: 'TRƯỜNG ĐẠI HỌC QUỐC TẾ',
    desc: 'Trường Đại học Quốc Tế - Đại học Quốc gia TP.HCM',
    phone: '(028) 37244270',
    email: 'info@hcmiu.edu.vn',
    address: 'Khu phố 6, Phường Linh Trung, Thành phố Thủ Đức, Thành phố Hồ Chí Minh',
    fax: '(028) 37244271',
    logo: '@assets/background/logo.png',
    color: '#045993',
  },
  ftu: {
    name: 'TRƯỜNG ĐẠI HỌC NGOẠI THƯƠNG',
    desc: 'Trường Đại học Ngoại thương ',
    phone: '',
    email: '',
    address: ' 91 Phố Chùa Láng, Láng Thượng, Đống Đa, Hà Nội',
    fax: '',
    logo: '@assets/background/FTU.png',
    color: '#be191f',
  },
  dhhv: {
    name: 'TRƯỜNG ĐẠI HỌC HÙNG VƯƠNG TPHCM',
    desc: 'Trường đại học Hùng Vương TPHCM ',
    phone: '',
    email: '',
    address: ' 736 Đ. Nguyễn Trãi, Phường 11, Quận 5, Thành phố Hồ Chí Minh',
    fax: '',
    logo: '@assets/background/DHHV.png',
    color: '#182a62',
  },
  tnut: {
    name: 'TRƯỜNG ĐẠI HỌC KỸ THUẬT CÔNG NGHIỆP',
    desc: 'THAINGUYEN UNIVERSITY OF TECHNOLOGY',
    phone: '(84)2083847145',
    email: 'office@tnut.edu.vn',
    address: 'Số 666 Đường 3-2, P.Tích Lương, TP Thái Nguyên - Tỉnh Thái Nguyên',
    fax: '',
    logo: '@assets/background/TNUT.png',
    color: '#045993',
  },
}

export const bankList: TypeMethodPayment[] = [
  {
    icon: 'BIDV',
    title: 'Ngân hàng BIDV',
    description: '',
    id: 0,
  },
]
export const listMethodt: TypeMethodPayment[] = [
  {
    icon: 'VNPT',
    title: 'Cổng thanh toán VNPay',
    description: '',
    id: 1,
  },
  {
    icon: 'VIETQR',
    title: 'Cổng thanh toán VNPay',
    description: '',
    id: 2,
    child: [
      { icon: 'MOMO', title: 'MOMO', description: '', id: 3 },
      { icon: 'VIETTELPAY', title: 'VIETTEL MONEY', description: '', id: 4 },
    ],
  },
  {
    icon: 'VNPAY-qr',
    title: 'Thanh toán quét mã ',
    description: '',
    id: 5,
    img: 'VNPAY',
  },
  {
    icon: 'VISA',
    title: 'Thẻ quốc tế',
    description: 'Visa, Mastercard',
    id: 6,
  },
  {
    icon: 'bank',
    title: 'Thẻ ngân hàng nội địa',
    description: 'Ngân hàng trong nước',
    id: 7,
  },
  {
    icon: 'Vi',
    title: 'Ví điện tử',
    description: '',
    id: 8,
    child: [
      { icon: 'MOMO', title: 'MOMO', description: '', id: 9 },
      { icon: 'ZaloPay', title: 'ZALOPAY', description: '', id: 10 },
      { icon: 'VNPAY', title: 'VNPAY', description: '', id: 11 },
    ],
  },
]

export const PaymentMethod = [
  { id: PaymentMethodKey.VNPAYQR, name: PaymentMethodKey.VNPAY, type: PaymentMethodKey.QR },
  { id: PaymentMethodKey.BIDV, name: 'Ngân hàng BIDV', type: PaymentMethodKey.QR },
  { id: PaymentMethodKey.ONEPAY, name: 'Onepay', type: PaymentMethodKey.QR },
  { id: PaymentMethodKey.SHB, name: PaymentMethodKey.SHB, type: PaymentMethodKey.SHB },
  { id: PaymentMethodKey.ACB, name: PaymentMethodKey.ACB, type: PaymentMethodKey.ACB },
]

export const paymentQR = [
  { key: PaymentMethodKey.VNPAYQR, title: PaymentMethodKey.VNPAYQR, icon: PaymentMethodKey.VNPAYQR },
  { key: PaymentMethodKey.BIDV, title: 'BIDV QR', icon: PaymentMethodKey.VNPAYQR },
  { key: PaymentMethodKey.ONEPAY, title: 'Onepay', icon: PaymentMethodKey.VNPAYQR },
]
