import Loading from '@components/Loading'
import Footer from '@components/shared/Footer'
import Header from '@components/shared/Header'
import { Version } from '@utils/constan'
import UseGetEdubillDetail from '@utils/UseGetEdubillDetail.hook'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type DesktopFrameProps = {
  children: React.ReactNode
}

const DesktopFrame = (props: DesktopFrameProps) => {
  const loading = useSelector<RootState>((state) => state.app.loading)
  const hidden = useSelector<RootState>((state) => state.app.hidden)
  const device = useSelector<RootState>((state) => state.app.device)
  const isHome = useSelector<RootState>((state) => state.app.isHome)

  const { handleGetBillList } = UseGetEdubillDetail()

  const { children } = props

  useEffect(() => {
    handleGetBillList()
  }, [])

  return (
    <>
      {!hidden ? (
        <div className={`flex-col min-h-screen content-between relative ${'flex'} `}>
          <div className="min-h-[65.4vh]">
            {device === 'web' && <Header />}
            {loading && <Loading />}
            <div className={`main frame-items px-2 xl:px-6 ${loading ? 'hidden' : ''}`}>
              <div>{children}</div>
            </div>
            {
              <p
                className={`text-[#595959] text-right text-[12px] mt-14 ${
                  isHome ? 'absolute bottom-[20px] right-[20px]' : ''
                }`}
              >
                {Version}
              </p>
            }
          </div>
          {device === 'web' && <Footer />}
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  )
}
export default DesktopFrame
