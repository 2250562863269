import SocketCalling from '@hooks/SocketCalling'
import { NetWorkService, validResponse } from '@utils/API'
import { LIST_BANK, LIST_VISA, PaymentMethodKey } from '@utils/constan'
import { detectEnvUat } from '@utils/general'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { setEduBillDetail, setLoading, Type_Initstate } from 'store/appSlice'
import { BillType, IPaymentMethod, TypeMethodPayment } from '../../types/general'
import View from './components/View'
import useConfirmPayment from './hooks/useConfirmPayment'
import useRenderMethod from './hooks/useRenderMethod'

export interface Partner {
  partnerCode: string
  partnerName: string
  status: number
}

const ConfirmPayment = () => {
  const { Post } = NetWorkService
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [payment, setPayment] = useState('')
  const [napas, setNapas] = useState(LIST_BANK)
  const [imgSHB, setImgSHB] = useState('')
  const [imgACB, setImgACB] = useState('')
  const [imgBidv, setImgBidv] = useState('')
  const [visiable, setVisible] = useState(false)
  const [visiablesSHB, setVisibleSHB] = useState(false)
  const [visiablesACB, setVisibleACB] = useState(false)
  const [bills, setBills] = useState<BillType[]>([])
  const [toastMsgPTTT, setToastMsgPTTT] = useState(false)
  const [toastMsgHTTT, setToastMsgHTTT] = useState(false)
  const [tien_toi_thieu, set_tien_toi_thieu] = useState(0)
  const [listMethod, setListMethod] = useState<TypeMethodPayment[]>([])
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod[]>([])
  const [partner, setPartner] = useState<Partner[]>([])
  const [child, setChild] = useState<TypeMethodPayment | undefined>(undefined)
  const [transactionCode, setTransactionCode] = useState('')
  const [bodyPost, setBodyPost] = useState<any>({})

  const [itemPaymentMethod, setItemPaymentMethod] = useState<TypeMethodPayment | undefined>(undefined)
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)

  const {
    handleMapImg,
    handleMapChild,
    handleMapPayments,
    handleMapDescription,
    handleMapPaymentsTitle,
    handleHiddenPaymentMethod,
  } = useRenderMethod({ bills, partner, paymentMethod })

  const {
    handleGetBillList,
    handleCallPaymentSHB,
    handleCallPaymentACB,
    handleCallPaymentBIDV,
    handleCallPaymentONEPAY,
    handleGetPaymentMethod,
    handleGetPartners,
  } = useConfirmPayment({ bills, child, setPartner, setPaymentMethod })

  const handleCreateBill = async () => {
    try {
      !itemPaymentMethod && setToastMsgPTTT(true)
      !payment && setToastMsgHTTT(true)
      const defaultValue = 0
      let amountMin = bills.find((e) => e.tien_toi_thieu)
        ? bills.find((e) => e.tien_toi_thieu)?.tien_toi_thieu
        : defaultValue
      const amountMax = bills?.reduce((a, b) => a + +b.phai_thu, 0)
      if (bills.some((e) => e.is_bat_buoc_thanh_toan_het)) {
        amountMin = bills.reduce((a, b) => {
          if (b.is_bat_buoc_thanh_toan_het) {
            a += b.phai_thu
          }
          return a
        }, 0)
      }

      if (payment) {
        // eslint-disable-next-line
        let body: any = {
          ma_sv: appState.studenInfor.MaSV,
          bankCode: payment,
          so_phieu_bao: bills.map((e) => e.so_phieu_bao),
          is_nhap_tien: false,
        }

        if (tien_toi_thieu) {
          body = { ...body, is_nhap_tien: true, amount: tien_toi_thieu }
        }

        if (bills.every((e) => e?.is_nhap_tien)) {
          if (tien_toi_thieu < amountMin || tien_toi_thieu > amountMax) {
            return false
          }

          if (tien_toi_thieu > amountMin && tien_toi_thieu < amountMax && tien_toi_thieu % 1000) {
            return false
          }
        }

        dispatch(setLoading(true))
        let res

        if (child.id === PaymentMethodKey.BIDV) {
          setBodyPost({
            billIds: bills.map((e) => e.so_phieu_bao),
            userCustomAmount: body.is_nhap_tien,
            amount: body.is_nhap_tien ? body?.amount : 0,
          })
          res = await handleCallPaymentBIDV(body)
        } else if (child.id === PaymentMethodKey.ONEPAY) {
          res = await handleCallPaymentONEPAY(body)
        } else if (child.id === PaymentMethodKey.SHB) {
          res = await handleCallPaymentSHB(body)
        } else if (child.id === PaymentMethodKey.ACB) {
          res = await handleCallPaymentACB(body)
        } else {
          res = await Post({
            url: `/payment/organization/${params.orgCode}/school/${params.schoolCode}/payment/vnpay`,
            body,
          })
        }

        if (child.id === PaymentMethodKey.ACB && res?.success !== false && res?.code === 200) {
          setImgACB(res.data.qrLink)
          setVisibleACB(true)
        } else if (res && child.id === PaymentMethodKey.BIDV && !res?.error) {
          setImgBidv(res.data.vietQRImage)
          setVisible(true)
          setTransactionCode(res.data.transactionCode)
        } else if (res && validResponse(res) && res.data?.success !== false && res.data?.data?.success !== false) {
          if (child.id === PaymentMethodKey.ONEPAY) {
            window.location.replace(`${res.data.data.redirectUrl}`)
          } else if (child.id === PaymentMethodKey.SHB) {
            setImgSHB(res.data.data.vietQRImage)
            setVisibleSHB(true)
          } else {
            window.location.replace(`${res.data}`)
          }
        } else {
          console.log(res)
          message.error(
            res?.data?.data?.message ? res?.data?.data?.message : res.message ? res.message : 'Có lỗi xảy ra',
          )
        }
      }
    } catch (error) {
      console.log(error)
      message.error(error?.data?.data?.message ?? 'Có lỗi xảy ra')
      // navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    payment && setToastMsgHTTT(false)
  }, [payment])
  useEffect(() => {
    setToastMsgHTTT(false)
  }, [itemPaymentMethod])

  useEffect(() => {
    itemPaymentMethod && setToastMsgPTTT(false)

    if (itemPaymentMethod) {
      const priorityChoose = itemPaymentMethod.child.find((e) => itemPaymentMethod.priorityChoose === e.id)
      setChild(priorityChoose ? priorityChoose : itemPaymentMethod.child[0])
    }
  }, [itemPaymentMethod])

  const handleCheckLocationState = () => {
    if (!location.state) {
      navigate(
        `${detectEnvUat() ? '/uat' : ''}/organization/${params.orgCode}/school/${params.schoolCode}/student/${
          params.studentID
        }`,
      )
    }
  }

  useEffect(() => {
    if (child && child.id !== PaymentMethodKey.ATM && child.id !== PaymentMethodKey.VISA) {
      setPayment(`${child.id}`)
    } else if (child && child.id === PaymentMethodKey.ATM) {
      setPayment(napas[0].code)
    } else if (child && child.id === PaymentMethodKey.VISA) {
      setPayment(LIST_VISA[0].code)
    }
  }, [child])

  useEffect(() => {
    handleGetPaymentMethod()
    setNapas(LIST_BANK)
    handleCheckLocationState()
    handleGetBillList()
    handleGetPartners()
  }, [])

  useEffect(() => {
    itemPaymentMethod && itemPaymentMethod.id === 'NAPAS' && setPayment('')
  }, [itemPaymentMethod])

  useEffect(() => {
    const listSoPhieuBao = location.state ? location.state.currentValue : []
    setBills(listSoPhieuBao)
  }, [])

  const handleSetId = (id: string) => {
    switch (id) {
      case PaymentMethodKey.QR:
        return PaymentMethodKey.VNPAYQR

      case PaymentMethodKey.WALLET:
        return PaymentMethodKey.VNMART

      default:
        return id
    }
  }

  const handleSetListMethod = () => {
    paymentMethod &&
      setListMethod(
        paymentMethod.map((e) => ({
          description: handleMapDescription(e._id),
          icon: handleMapImg(e._id),
          id: handleSetId(e._id),
          title: e.name,
          priorityChoose: e.priorityChoose,
          status: e.status ? true : false,
          paymentsTitle: handleMapPaymentsTitle(e._id),
          payments: handleMapPayments(e._id),
          sign: handleHiddenPaymentMethod(e._id),
          child: handleMapChild(e._id),
        })),
      )
  }

  useEffect(() => {
    handleSetListMethod()
  }, [paymentMethod, partner])

  useEffect(() => {
    listMethod.length && setChild(listMethod[0].child[0])
    listMethod.length && setItemPaymentMethod(listMethod[0])
  }, [listMethod])

  useEffect(() => {
    // setPayment('')
    if (itemPaymentMethod && itemPaymentMethod?.id === PaymentMethodKey.WALLET) {
      setPayment(PaymentMethodKey.VNMART)
    }
  }, [itemPaymentMethod])

  useEffect(() => {
    if (appState?.listEdubillDetail.length !== 0) {
      const edubillDetail = appState?.listEdubillDetail.find((e) => e.orgCode === params.orgCode)
      dispatch(setEduBillDetail(edubillDetail))
    }
  }, [appState?.listEdubillDetail, params.orgCode])

  return (
    <>
      <View
        bodyPost={bodyPost}
        transactionCode={transactionCode}
        appState={appState}
        payment={payment}
        bills={bills}
        child={child}
        handleCreateBill={handleCreateBill}
        imgBidv={imgBidv}
        itemPaymentMethod={itemPaymentMethod}
        listMethod={listMethod}
        napas={napas}
        setChild={setChild}
        setItemPaymentMethod={setItemPaymentMethod}
        setPayment={setPayment}
        setVisible={setVisible}
        set_tien_toi_thieu={set_tien_toi_thieu}
        tien_toi_thieu={tien_toi_thieu}
        toastMsgHTTT={toastMsgHTTT}
        toastMsgPTTT={toastMsgPTTT}
        visiable={visiable}
        imgShb={imgSHB}
        imgACB={imgACB}
        setVisibleShb={setVisibleSHB}
        setVisibleACB={setVisibleACB}
        visiableShb={visiablesSHB}
        visiableACB={visiablesACB}
        paymentMethod={paymentMethod}
      />
      <SocketCalling transactionCode={transactionCode} />
    </>
  )
}

export default ConfirmPayment
