import { API_URL, NetWorkService, validResponse } from '@utils/API'
import { CODE_SUCCESS } from '@utils/constan'
import { detectEnvUat, detectWebview } from '@utils/general'
import { message } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { setDevice, setLoading, setStudenInfor, Type_Initstate } from 'store/appSlice'
import { Partner } from '..'
import {
  BillType,
  IPaymentMethod,
  IPaymentMethodResponse,
  ItemBillResponse,
  TypeMethodPayment,
} from '../../../types/general'

interface PropsUseConfirmPayment {
  bills: BillType[]
  child: TypeMethodPayment
  setPartner: React.Dispatch<React.SetStateAction<Partner[]>>
  setPaymentMethod: React.Dispatch<React.SetStateAction<IPaymentMethod[]>>
}
type AnyObject = { [key: string]: any }
const useConfirmPayment = ({ bills, child, setPartner, setPaymentMethod }: PropsUseConfirmPayment) => {
  const { Get, Post } = NetWorkService
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { studenInfor } = useSelector<RootState, Type_Initstate>((state) => state.app)

  // ===== ACB ====== //
  const handleCallPaymentACB = async (body: AnyObject) => {
    try {
      const dataPost = {
        billCodes: bills.map((e) => e.so_phieu_bao),
        userCustomAmount: body.is_nhap_tien,
        amount: body.is_nhap_tien ? body?.amount : 0,
      }

      const res = await fetch(
        `${API_URL}/api/organization/${params.orgCode}/school/${params.schoolCode}/studentCode/${studenInfor?.MaSV}/payment/acb`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Important to specify the content type as JSON
          },
          body: JSON.stringify(dataPost),
        },
      ).then((res) => res.json())

      return res
    } catch (error) {
      console.log(error)

      return {}
    }
  }

  const handleCallPaymentSHB = async (body: AnyObject) => {
    try {
      const res = await Post({
        url: `/payment/organization/${params.orgCode}/school/${params.schoolCode}/payment/shb`,
        body,
      })
      return res
    } catch (error) {
      console.log(error)
      return {}
    }
  }
  // ===== ONEPAY ====== //
  const handleCallPaymentONEPAY = async (body: AnyObject) => {
    try {
      const returnUrl =
        window.location.origin +
        `${detectEnvUat() ? '/uat' : ''}/payment-result/onepay/organization/${params.orgCode}/school/${
          params.schoolCode
        }/student/` +
        window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]

      const res = await Post({
        url: `/api/organization/${params.orgCode}/school/${params.schoolCode}/studentCode/${studenInfor?.MaSV}/payment/onepay`,
        body: {
          billCodes: bills.map((e) => e.so_phieu_bao),
          userCustomAmount: body.is_nhap_tien,
          amount: body.is_nhap_tien ? body?.amount : 0,
          returnUrl,
          currUrl: returnUrl,
          method: child.type,
        },
      })

      return res
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  // ===== BIDV ====== //
  const handleCallPaymentBIDV = async (body: AnyObject) => {
    try {
      dispatch(setLoading(true))
      const res = await fetch(
        `${API_URL}/payment/organization/${params.orgCode}/school/${params.schoolCode}/studentCode/${studenInfor?.MaSV}/payment/emulator`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Important to specify the content type as JSON
          },
          body: JSON.stringify({
            billIds: bills.map((e) => e.so_phieu_bao),
            userCustomAmount: body.is_nhap_tien,
            amount: body.is_nhap_tien ? body?.amount : 0,
          }),
        },
      ).then((res) => res.json())

      return res
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  // lấy Infor của sinh viên
  const handleGetBillList = async () => {
    try {
      dispatch(setLoading(true))
      const index = window.location.href.split('/').length
      let secretCode = window.location.href.split('/')[index - 1]
      const isWebview = detectWebview().Webview
      if (secretCode.split('?').length > 1) {
        secretCode = secretCode.split('?')[secretCode.split('?').length - 2]
      }
      dispatch(setDevice(isWebview === 'Yes' ? 'app' : 'web'))
      const url = `/api/organization/${params.orgCode}/school/${params.schoolCode}/secretCode/${secretCode}/bill`
      // eslint-disable-next-line
      const res: any = await Get<ItemBillResponse>({
        url,
      })
      if (res && validResponse(res) && res.code === CODE_SUCCESS && res.data.data.success !== false) {
        dispatch(setStudenInfor(res.data.data.student))
      } else {
        message.error(res.data.data.message)
        navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
      }
    } catch (error) {
      console.log(error)
      navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleGetPaymentMethod = async () => {
    try {
      const res = await Get<IPaymentMethodResponse>({
        url: `/api/school/${params.schoolCode}/paymentMethod/detail`,
      })
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        if (!res.data?.data?.paymentMethod || res.data?.data?.paymentMethod.length === 0) {
          message.error('Chưa thiết lập phương thức thanh toán. Vui lòng liên hệ đóng trực tiếp tại nhà trường!')
        }

        setPaymentMethod(res.data?.data?.paymentMethod ?? [])
      } else {
        res && validResponse(res) && message.error(res.data.data.message)
        navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
      }
    } catch (error) {
      console.log(error)
      message.error('Có lỗi xảy ra')
      navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
    }
  }


  // lấy danh sách đối tác thanh toán
  const handleGetPartners = async () => {
    try {
      const res: any = await Get({
        url: `api/organization/${params.orgCode}/school/${params.schoolCode}/partner/list`,
      })
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        setPartner(res.data.data.partners)
      } else {
        res && validResponse(res) && message.error(res.data.data.message)
      }
    } catch (error) {
      message.error('Có lỗi xảy ra')
      console.log(error)
    }
  }

  return {
    handleCallPaymentBIDV,
    handleCallPaymentONEPAY,
    handleGetBillList,
    handleCallPaymentSHB,
    handleCallPaymentACB,
    handleGetPaymentMethod,
    handleGetPartners,
  }
}

export default useConfirmPayment
