import moment from 'moment'
import React, { useEffect, useState } from 'react'

interface IPropCellRenderStatus {
  value: string
}

const CellRenderStatus = ({ value }: IPropCellRenderStatus) => {
  const [currenTime, setCurrenTime] = useState(new Date().getTime())
  const [createdAt, setCreatedAt] = useState('')

  useEffect(() => {
    if (createdAt) {
      const timeOut = setInterval(() => {
        setCurrenTime(currenTime + 1000)
      }, 1000)

      if (new Date(createdAt).getTime() < currenTime) {
        clearInterval(timeOut)
      }
      // console.log('run')
      return () => {
        clearInterval(timeOut)
      }
    }
  }, [currenTime])

  useEffect(() => {
    const splitValue = value.split('-')
    if (splitValue.length === 2) {
      setCreatedAt(splitValue[1])
    }
  }, [])

  return (
    <div className="text-right flex flex-row justify-end items-center">
      {value.split('-')[0]}{' '}
      {createdAt && new Date(createdAt).getTime() > currenTime && (
        <p className="text-[#202027] text-[12px] font-[500] pl-2">
          ({moment(new Date(createdAt).getTime() - currenTime).format('mm:ss')})
        </p>
      )}
    </div>
  )
}

export default CellRenderStatus
